import React from 'react';
import Container from './Container';
import '../custom.css';

class ColorScheme extends React.Component {
    state = {
        colorSchemes: [
            {
                colors: [
                    { hex: '#466C7C', name: 'Poseidon' },
                    { hex: '#D0A29E', name: 'Rose Pottery' },
                    { hex: '#F3D6D7', name: 'Lotus Petal' },
                    { hex: '#A4A783', name: 'Rejuvenation' },
                    { hex: '#83A28F', name: 'Mossy Bench' },
                    { hex: '#E9DBC4', name: 'Moongaze' }
                ]
            }
        ]
    };

    renderColorPalette = (palette) => {
        return (
            <div className="flex justify-around flex-wrap p-5 bg-white rounded-lg shadow-md">
                {palette.colors.map((color, index) => (
                    <div key={index} className="w-24 p-2 flex flex-col items-center">
                        <div className="h-24 w-24 rounded-full mb-2" style={{ backgroundColor: color.hex }}></div>
                        <span className="text-wedding-contrast text-sm font-bold text-center">{color.name}</span>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            <section className="py-12 bg-wedding-bg">
                <Container>
                    <h2 className="text-4xl font-bold text-center my-6 font-serif-heading text-wedding-text">Wedding Color Schemes</h2>
                    <p className="text-center text-wedding-contrast">We kindly request formal attire for our wedding. However, we understand that the weather may be warm, so please feel free to choose lighter fabrics and more comfortable options. Gentlemen, think about skipping the jacket and opting for a dress shirt and tie. Ladies, consider breathable and elegant dresses. We want everyone to feel comfortable while celebrating with us.</p>
                    {this.state.colorSchemes.map((scheme, index) => (
                        <div key={index} className="mb-4">
                            <h3 className="text-lg font-semibold mb-2 text-wedding-contrast">{scheme.name}</h3>
                            {this.renderColorPalette(scheme)}
                        </div>
                    ))}
                </Container>
            </section>
        );
    }
}

export default ColorScheme;

import React, { Suspense } from 'react';
import "./custom.css";
import "./styles.css";
import "./output.css";
import ColorScheme from './components/ColorScheme';

const Navbar = React.lazy(() => import('./components/Navbar'));
const WelcomeSection = React.lazy(() => import('./components/WelcomeSection'));
const Accommodations = React.lazy(() => import('./components/Accommodations'));
const OurStorySection = React.lazy(() => import('./components/OurStorySection'));
const EventDetailsSection = React.lazy(() => import('./components/EventDetailsSection'));
const RsvpSection = React.lazy(() => import('./components/RsvpSection'));
const RegistrySection = React.lazy(() => import('./components/RegistrySection'));
const Footer = React.lazy(() => import('./components/Footer'));
const TopVisualWithHeader = React.lazy(() => import('./components/TopVisualWithHeader'));
const Itinerary = React.lazy(() => import ('./components/Itinerary'));
const Seating = React.lazy(() => import ('./components/Seating'));

function App() {
  return (
    <div className="min-h-screen bg-wedding-bg">
      <Suspense fallback={<div>Loading...</div>}>
        <Navbar className="z-50 fixed top-0 left-0 w-full" />
        <TopVisualWithHeader />
        <main className="container mx-auto p-4">
          <OurStorySection />
          <EventDetailsSection />
          <Seating /> 
          <Accommodations />
          <Itinerary />
          <ColorScheme />
          <RsvpSection />
          <RegistrySection />
          <Footer />
        </main>
      </Suspense>
    </div>
  );
}

export default App;
